import Axios from 'axios';
import { url } from '../constants';

export async function getList(endpoint, params) {
  return Axios({
    url: `${url}/${endpoint}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'GET',
    params,
  });
}
export async function getOne(endpoint, id) {
  return Axios({
    url: `${url}/${endpoint}/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'GET',
  });
}

export async function getOneByTwoWords(wordOne, wordTwo, id) {
  return Axios({
    url: `${url}/${wordOne}/${id}/${wordTwo}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'GET',
  });
}
export async function patch(endpoint, id, patchData) {
  return Axios({
    url: `${url}/${endpoint}/${id}`,
    data: patchData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'PATCH',
  });
}

export async function post(endpoint, postData) {
  return Axios({
    url: `${url}/${endpoint}`,
    data: postData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'POST',
  });
}

export function updateOne(endpoint, id, data = {}) {
  return Axios.put(`${url}/${endpoint}/${id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
}
