// eslint-disable-next-line import/no-cycle
import {
  getRequestRepository,
  getRequestsRepository,
  postRequestRepository,
  getMoratoriumRepository,
  updateMoratoriumRepository,
  updateRequestRepository,
} from '../Infrastructure/Requests.repository';

export function getRequestsLogic(params) {
  return getRequestsRepository(params);
}
export function getRequestLogic(id) {
  return getRequestRepository(id);
}

export function getMoratoriumLogic(id) {
  return getMoratoriumRepository(id);
}

export function updateMoratoriumLogic(moratorium, values) {
  let valueQ = values?.q_amount;

  if (valueQ === false) {
    valueQ = 0;
  } else if (valueQ === true) {
    valueQ = 1;
  }

  const newValues = {
    ...values,
    q_amount: valueQ,
  };

  const data = {
    ...newValues,
    ...moratorium,
    capital: Number(values.capital),
    date_final: values.date_final ? values.date_final.format('YYYY-MM-DD') : null,
    date_initial: values.date_initial ? values.date_initial.format('YYYY-MM-DD') : null,
  };
  delete data.id;

  return updateMoratoriumRepository(moratorium.id, data);
}

export function updateRequestLogic(selected, values) {
  const newValues = {
    ...values,
    user: selected?.user_data?.username !== values.user ? values.user : selected.user,
    request_parent:
      selected?.request_parent_data?.token === values.request_parent
        ? selected?.request_parent
        : values?.request_parent,
    user_lender:
      selected?.user_lender_data?.username === values.user_lender ? selected?.user_lender : values?.user_lender,
    user_aval: selected?.user_aval_data?.username === values.user_aval ? selected?.user_aval : values?.user_aval,
  };

  delete selected.user_data;
  delete selected.user_lender_data;
  delete selected.request_parent_data;
  delete selected.user_aval_data;

  const data = {
    ...selected,
    ...newValues,
    activation_date: values.activation_date ? values.activation_date.format('YYYY-MM-DD') : null,
    amount: Number(values.amount),
    commission_admin_lender: Number(values.commission_admin_lender),
    commission_admin: Number(values.commission_admin),
    commission_by_each_payment: Number(values.commission_by_each_payment),
    opening_commission: Number(values.opening_commission),
    date: values.date ? values.date.format('YYYY-MM-DD') : null,
    finish_date: values.finish_date ? values.finish_date.format('YYYY-MM-DD') : null,
    payment_time: Number(values.payment_time),
    payment_type: Number(values.payment_type),
    rate: Number(values.rate),
    iva: Number(values.iva),
    isr_withholdings: Number(values.isr_withholdings || 0),
    iva_withholdings: Number(values.iva_withholdings || 0),
    recognition: Number(values.recognition),
    status: Number(values.status),
    waiting_funds_date: values.waiting_funds_date ? values.waiting_funds_date.format('YYYY-MM-DD') : null,
  };

  return updateRequestRepository(data.id, data);
}

export function postRequestLogic(values) {
  const current = new Date();

  const dateCurrent = `${current.getFullYear()}-${`0${current.getMonth() + 1}`.slice(
    -2
  )}-${`0${current.getDate()}`.slice(-2)}`;

  // eslint-disable-next-line radix
  const amountInt = Number(values.amount);
  // eslint-disable-next-line radix
  const rateInt = parseInt(values.rate);

  const guaranteeValue = (() => {
    if (values.guarantee === undefined) return false;
    return values.guarantee;
  })();

  const avalRequired = (() => {
    if (values.aval_required === undefined) return false;
    return values.aval_required;
  })();

  const data = {
    user: values.user,
    request_parent: values.request_parent,
    amount: amountInt,
    payment_time: values.payment_time,
    description: values.description,
    guarantee: guaranteeValue,
    rate: rateInt,
    payment_type: values.payment_type,
    title: values.title,
    recognition: values.recognition,
    date: dateCurrent,
    aval_required: avalRequired,
    user_aval: values.user_aval,
    user_lender: values.user_lender,
    version: values.version,
    version_commission: values.version_commission,
  };

  // eslint-disable-next-line no-console
  console.log(data);

  return postRequestRepository(data);
}
