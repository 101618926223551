import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'requests',
  initialState: {
    records: [],
    selected: null,
    count: 0,
  },

  reducers: {
    setRecords: (state, { payload: records }) => {
      return {
        ...state,
        records,
      };
    },

    selectRecord: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },

    cancelSelectedRecord: (state) => {
      return {
        ...state,
        selected: null,
      };
    },

    updateRecord: (state, { payload }) => {
      const { index, updated } = payload;
      const records = [...state.records];
      records[index] = { ...records[index], ...updated };
      return {
        ...state,
        records,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRecords, selectRecord, cancelSelectedRecord, updateRecord } = slice.actions;

export default slice.reducer;

export const getRequestSelector = (store) => store.requests.records;
export const getSelectedRequest = (store) => store.requests.selected;
