import React from 'react';
import { Link } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { main } from '../../Utils/Colors';
import { logout } from '../Aplication/Auth.logic';

export default function LogoutButton() {
  const logoutClick = async () => {
    await logout();
  };

  return (
    <Link
      to="/login"
      style={{
        color: main,
        margin: '0.5em',
        fontWeight: '700',
        fontSize: '14px',
      }}
      onClick={logoutClick}
    >
      <LogoutOutlined style={{ margin: '0.2em' }} />
      Cerrar sesión
    </Link>
  );
}
