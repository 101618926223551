import { getList, getOne, updateOne, post, getOneByTwoWords } from '../../Data/provider';

export function getRequestsService(params) {
  return getList('requests', params);
}

export function getRequestService(id) {
  return getOne('requests', id);
}

export function getMoratoriumService(id) {
  return getOneByTwoWords('requests', 'moratoria', id);
}

export function updateRequestService(id, data) {
  return updateOne('requests', id, data);
}

export function updateMoratoriumService(id, data) {
  return updateOne('moratoria', id, data);
}

export function postRequestService(data) {
  return post('requests', data);
}
