import AuthModel from '../Domain/Auth.model';
import LoginModel from '../Domain/Login.model';
import ResetPassswordModel from '../Domain/ResetPassword.model';
import {
  getRolesRepository,
  getSessionRepository,
  loginRepository,
  logoutRepository,
  requestPasswordRepository,
  resetPasswordRepository,
} from '../Infrastructure/Login.repository';

export async function login({ email, password }) {
  const loginModel = new LoginModel(email, password);
  const repo = await loginRepository(loginModel);
  const { token, userId } = repo;
  localStorage.setItem('token', token);
  localStorage.setItem('userId', userId);
}

export async function isAuthorized() {
  const roles = await getRolesRepository();
  return roles.isAuthorized();
}

export async function isValidSession() {
  const session = await getSessionRepository();
  return session.status === 200;
}

export async function logout() {
  const authModel = new AuthModel({
    token: localStorage.getItem('token'),
    userId: localStorage.getItem('userId'),
  });
  localStorage.removeItem('token');
  localStorage.removeItem('userId');

  await logoutRepository(authModel);
}

export async function resetPassword(email) {
  const resetPasswordModel = new ResetPassswordModel(email);
  return resetPasswordRepository(resetPasswordModel);
}
export async function requestPassword(id) {
  return requestPasswordRepository(id);
}
