import { Button, message, Modal, Popconfirm } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { main } from '../../Utils/Colors';
import { requestPassword } from '../../Login/Aplication/Auth.logic';

export default function PasswordTemporal(data) {
  const { data: user } = data;
  const { id } = user;
  const onFinish = async () => {
    requestPassword(id)
      .then((e) => {
        const temporaryPassword = e.data.temporary;

        Modal.info({
          title: 'Contraseña Temporal',
          content: (
            // eslint-disable-next-line react/react-in-jsx-scope
            <div>
              {/* eslint-disable-next-line react/react-in-jsx-scope */}
              <p>
                Favor de copiar tu contraseña temporal
                {/* eslint-disable-next-line react/react-in-jsx-scope */}
                <br />
                {/* eslint-disable-next-line react/react-in-jsx-scope */}
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    fontSize: '38px',
                    fontWeight: 'bold',
                  }}
                >
                  {temporaryPassword}
                  {/* eslint-disable-next-line react/react-in-jsx-scope */}
                  <Button
                    type="primary"
                    /* eslint-disable-next-line react/react-in-jsx-scope */
                    icon={<CopyOutlined />}
                    onClick={() => {
                      navigator.clipboard
                        .writeText(temporaryPassword)
                        .then(() => {
                          message.success('Contraseña copiada al portapapeles');
                        })
                        .catch((err) => {
                          const messageErro = err;
                          message.error(`Error al copiar la contraseña ${messageErro}`);
                        });
                    }}
                  />
                </span>
              </p>
            </div>
          ),
          okText: 'Aceptar',
          onOk() {},
        });
      })
      .catch((e) => {
        const messageError = e;
        message.error(`Error al solicitar contraseña ${messageError}`);
      });
  };
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Popconfirm
      title="¿Estas seguro que quieres solicitar una contraseña temporal?"
      onConfirm={onFinish}
      okText="Contraseña Temporal"
      cancelText="Cancelar"
    >
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <Button
        type="primary"
        style={{
          background: main,
        }}
        htmlType="button"
      >
        Contraseña Temporal
      </Button>
    </Popconfirm>
  );
}
