import Params from '../../Data/Domain/Params.model';
import RequestModel from '../Domain/Request.model';
import {
  getRequestService,
  getRequestsService,
  postRequestService,
  updateRequestService,
  getMoratoriumService,
  updateMoratoriumService,
  // eslint-disable-next-line import/named
} from './Requests.service';
import MoratoriumModel from '../Domain/Moratorium.model';

export async function getRequestsRepository(params) {
  const response = await getRequestsService(Params.buildFilters(params));
  return response.data.resources.map((request) => new RequestModel(request));
}
export async function getRequestRepository(id) {
  const response = await getRequestService(id);
  return new RequestModel(response.data);
}

export async function getMoratoriumRepository(id) {
  try {
    const response = await getMoratoriumService(id);

    if (!response.data || typeof response.data !== 'object') {
      throw new Error('response.data is not a valid object');
    }

    const moratoriumModels = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of response.data.resources) {
      moratoriumModels.push(new MoratoriumModel(item));
    }

    return moratoriumModels;
  } catch (error) {
    throw new Error('error al obtener el moratorio');
  }
}

export function updateMoratoriumRepository(id, request) {
  return updateMoratoriumService(id, request);
}

export function updateRequestRepository(id, request) {
  return updateRequestService(id, request);
}

export function postRequestRepository(request) {
  return postRequestService(request);
}
