import Axios from 'axios';
import { url } from '../../constants';

export function loginService(credentials) {
  return Axios.post(`${url}/login`, credentials);
}

export function getRoles() {
  return Axios({
    url: `${url}/roles`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    method: 'POST',
  });
}
export async function getSession() {
  try {
    const request = await Axios({
      url: `${url}/sessions`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      method: 'POST',
    });
    return request;
  } catch (error) {
    return error;
  }
}

export function logoutService({ token }) {
  return Axios({
    url: `${url}/logout`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
  });
}

export function resetPasswordService(resetPasswordModel) {
  return Axios.put(`${url}/passwords`, resetPasswordModel, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
}
export function requestPasswordService(id) {
  return Axios.post(
    `${url}/users/${id}/passwords`,
    { type: 'temporary' },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  );
}
