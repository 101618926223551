import { Col, Layout, Row } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { background } from '../../Utils/Colors';
import { getUser, getUsers } from '../Application/Users.business';
import UsersForm from './Users.form';
import Searcher from './Searcher';

const { Content } = Layout;

export default function Users() {
  const { id } = useParams();
  useEffect(() => {
    getUsers();
    getUser(id);
  }, []);

  return (
    <Layout>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          background,
        }}
      >
        <Row
          justify="space-between"
          style={{
            width: '100%',
          }}
        >
          <Col span={24}>
            <Searcher userID={id} />
          </Col>
          <Col span={24}>
            <UsersForm userID={id} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
