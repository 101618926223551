import Params from '../../Data/Domain/Params.model';
import UserModel from '../Domain/User.model';
import { getUserService, getUsersService, updateUserService } from './Users.service';

export async function getUsersRepository(params) {
  const response = await getUsersService(Params.buildFilters(params));
  return response.data.resources.map((request) => new UserModel(request));
}
export async function getUserRepository(id) {
  const response = await getUserService(id);
  return new UserModel(response.data);
}

export async function updateUserRepository(id, request) {
  return updateUserService(id, request);
}
