/* eslint-disable react/prop-types */
import React from 'react';
import { grisObscuro, main, normal } from './Colors';

const styles = {
  title: {
    // margin: "1em",
    fontWeight: '700',
    fontSize: '32px',
    color: main,
  },
  subtitle: {
    // margin: "1em",
    fontWeight: '800',
    fontSize: '24px',
    color: grisObscuro,
  },
  textbody: {
    fontWeight: '600',
    fontSize: '12px',
    color: normal,
  },
  tag: {
    padding: '0.1em 0.5em',
    color: main,
    fontWeight: '800',
    borderRadius: '20px',
    boxShadow: '0px 3px 5px 0px rgb(181,181,181, 0.5)',
  },
};

export function Title({ children }) {
  return <span style={styles.title}>{children}</span>;
}
export function SubTitle({ children, color = grisObscuro }) {
  return <span style={{ ...styles.subtitle, color }}>{children}</span>;
}
export function TextBody({ children }) {
  return <span style={styles.textbody}>{children}</span>;
}

export function Tag({ children }) {
  return <span style={styles.tag}>{children}</span>;
}

export function DateFormatter({ date }) {
  return <span style={styles.textbody}>{date?.toLocaleDateString()}</span>;
}
