import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'logs',
  initialState: {
    list: [],
    selected: null,
    count: 0,
  },
  reducers: {
    setUsers: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },

    countUsers: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },

    selectUser: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
    cancelSelectedUser: (state) => {
      return {
        ...state,
        selected: null,
      };
    },
  },
});

// Action creators are generated for each case reducer funection
export const { setUsers, countUsers, selectUser, cancelSelectedUser } = slice.actions;

export default slice.reducer;

export const getUsersSelector = (store) => store.users.list;
export const getSelectedUserSelector = (store) => store.users.selected;
export const getUsersCountSelector = (store) => store.users.count;
