import { cancelSelectedUser, selectUser, setUsers } from './Users.reducer';
import store from '../../Redux/store';

export function saveUsersList(records) {
  store.dispatch(setUsers(records));
}
export function saveSelectedUser(record) {
  store.dispatch(selectUser(record));
}

export function deselectUser() {
  store.dispatch(cancelSelectedUser());
}
