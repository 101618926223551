import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'logs',
  initialState: {
    logs: [],
    chartLogs: [],
    selected: {},
    count: 0,
  },
  reducers: {
    setLogs: (state, { payload: logs }) => {
      return {
        ...state,
        logs,
      };
    },

    countLogs: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },

    setChartLogs: (state, { payload: chartLogs }) => {
      return {
        ...state,
        chartLogs,
      };
    },

    selectLog: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
  },
});

// Action creators are generated for each case reducer funection
export const { setLogs, countLogs, setChartLogs, selectLog } = slice.actions;

export default slice.reducer;

export const getLogs = (store) => store.logs.logs;
export const getChartLogs = (store) => store.logs.chartLogs;
export const getSelectedLog = (store) => store.logs.selected;
export const getLogsCount = (store) => store.logs.count;
