import React from 'react';
import { Layout, Menu } from 'antd';
import { /* FundOutlined, */ TeamOutlined, SolutionOutlined } from '@ant-design/icons';
import { Link, useRouteMatch } from 'react-router-dom';
// import Logs from '../Logs/UI/Logs';
import Requests from '../Requests/UI/Requests';
import './SiteLayout.css';
import LogoutButton from '../Login/UI/Logout';
import { background } from '../Utils/Colors';
import Users from '../Users/UI/Users.ui';

const { Header, Content, Sider } = Layout;

const pages = {
  /* logs: {
    title: 'Logs',
    page: <Logs />,
    icon: <FundOutlined />,
  }, */
  requests: {
    title: 'Solicitudes',
    page: <Requests />,
    icon: <SolutionOutlined />,
  },
  users: {
    title: 'Usuarios',
    page: <Users />,
    icon: <TeamOutlined />,
  },
};

export default function SiteLayout() {
  let { url = '/requests' } = useRouteMatch();
  [, url] = url.split('/');

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background,
          padding: '1em 2em',
        }}
      >
        <img
          style={{
            width: '20vh',
          }}
          alt="Finloop logo"
          src="https://finloop.enlanube.app/assets/img/login/logo.png"
        />
        <div
          style={{
            padding: '2px',
            textAlign: 'left',
            position: 'fixed',
            bottom: '0',
            zIndex: '9999',
            fontFamily: 'Arial, sans-serif',
            fontSize: '12px',
          }}
        >
          v 1.5.6
        </div>

        <LogoutButton />
      </Header>
      <Content style={{ padding: '1em 1em', background }}>
        <Layout style={{ padding: '24px 0', height: '100vh', background }}>
          <Sider style={{ background }}>
            <Menu mode="inline" defaultSelectedKeys={[url]} style={{ height: '100%', background }}>
              {Object.keys(pages).map((key) => (
                <Menu.Item key={key}>
                  {pages[key].icon}
                  <Link
                    style={{
                      color: 'inherit',
                    }}
                    to={`/${key}`}
                  >
                    {pages[key].title}
                  </Link>
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Content
            style={{
              padding: '0 24px',
              minHeight: 280,
              background,
            }}
          >
            {pages[url].page}
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
}
