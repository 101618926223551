import { Button, Card, Col, DatePicker, Form, Input, InputNumber, message, Row, Tooltip, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/named
import { useHistory } from 'react-router-dom';
import { getMoratorium, updateMoratorium } from '../Aplication/Requests.business';

// eslint-disable-next-line react/prop-types
const Moratorium = ({ selected }) => {
  const [moratoriumValues, setMoratoriumValues] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    getMoratorium(selected.id)
      .then((data) => {
        setMoratoriumValues(data);
      })
      .catch((e) => {
        const messageError = e;
        message.error(`Error al modificar solicitud ${messageError}`);
      });
    // eslint-disable-next-line react/prop-types
  }, [selected.id]);

  return (
    <>
      {moratoriumValues.map((moratorium, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MoratoriumForm key={index} moratorium={moratorium} index={index} />
      ))}
    </>
  );
};

// eslint-disable-next-line react/prop-types
const MoratoriumForm = ({ moratorium, index }) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = (values) => {
    // eslint-disable-next-line no-console
    console.log(`Enviando formulario ${index}`);
    updateMoratorium(moratorium, values)
      .then(() => {
        // eslint-disable-next-line react/prop-types
        const UrlId = moratorium.id;
        message.success('Moratorio modificada con éxito');
        history.push(`/requests/${UrlId}`);
      })
      .catch((e) => {
        const messageError = e.request.response;
        message.error(`Error al modificar solicitud ${messageError}`);
      });
  };

  // eslint-disable-next-line react/prop-types
  let valueQ = moratorium?.q_amount;

  if (valueQ === 0) {
    valueQ = false;
  } else if (valueQ === 1) {
    valueQ = true;
  }

  const newMoratorium = {
    ...moratorium,
    q_amount: valueQ,
  };

  return (
    <Form form={form} onFinish={onFinish} initialValues={newMoratorium}>
      <Card
        /* eslint-disable-next-line react/prop-types */
        title={`Periodo ${moratorium.period}`}
        size="small"
        style={{ marginBottom: '20px' }}
        headStyle={{ fontSize: '20px', fontWeight: 'bold' }}
      >
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item
              name="capital"
              label="Capital pendiente"
              rules={[
                {
                  required: true,
                  message: 'El capital pendiente es obligatorio',
                },
                {
                  message: 'Solo se permiten dos dígitos',
                  pattern: /^\d+(\.\d{1,2})?$/,
                },
              ]}
            >
              <InputNumber
                style={{ width: 150, borderRadius: 20 }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            {/* eslint-disable-next-line react/prop-types */}
            <Form.Item hasFeedback name="q_amount" label="Montos Q" initialValue={moratorium?.q_amount}>
              {/* eslint-disable-next-line react/prop-types */}
              <Switch defaultChecked={moratorium?.q_amount} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              hasFeedback
              name="date_initial"
              label="Fecha Inicial"
              rules={[
                {
                  required: true,
                  message: 'La Fecha inicial es obligatorio',
                },
              ]}
            >
              <DatePicker style={{ borderRadius: 20 }} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              hasFeedback
              name="date_final"
              label="Fecha final"
              rules={[
                {
                  required: true,
                  message: 'La Fecha final es obligatorio',
                },
              ]}
            >
              <DatePicker style={{ borderRadius: 20 }} />
            </Form.Item>
          </Col>
          <Col span={3}>
            {/* eslint-disable-next-line react/prop-types */}
            <Form.Item hasFeedback name="paid" label="Pagado" initialValue={moratorium?.paid}>
              {/* eslint-disable-next-line react/prop-types */}
              <Tooltip placement="topLeft" title={moratorium?.paid ? 'Pagado' : 'No Pagado'}>
                {/* eslint-disable-next-line react/prop-types */}
                <Switch defaultChecked={moratorium?.paid} />
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item disabled name="amount_paid" label="Total pagado">
              <InputNumber
                disabled
                style={{ width: 150, borderRadius: 20 }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              disabled
              name="interest"
              label="Interés Moratorio"
              rules={[
                {
                  message: 'Solo se permiten dos dígitos',
                  pattern: /^\d+(\.\d{1,2})?$/,
                },
              ]}
            >
              <InputNumber
                disabled
                style={{ width: 150, borderRadius: 20 }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              disabled
              name="iva"
              label="IVA del Interés Moratorio"
              rules={[
                {
                  message: 'Solo se permiten dos dígitos',
                  pattern: /^\d+(\.\d{1,2})?$/,
                },
              ]}
            >
              <InputNumber
                disabled
                style={{ width: 150, borderRadius: 20 }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item
              disabled
              name="total"
              label="Total"
              rules={[
                {
                  message: 'Solo se permiten dos dígitos',
                  pattern: /^\d+(\.\d{1,2})?$/,
                },
              ]}
            >
              <InputNumber
                disabled
                style={{ width: 150, borderRadius: 20 }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item hasFeedback name="days" label="Días" disabled>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default Moratorium;
