import {
  saveRequestsList,
  // eslint-disable-next-line import/named
  saveSelectedRecord,
  // saveUpdateRecord,
} from '../Infrastructure/Requests.store';
import {
  getRequestLogic,
  getRequestsLogic,
  updateRequestLogic,
  postRequestLogic,
  getMoratoriumLogic,
  updateMoratoriumLogic,
} from './Requests.logic';

export async function getRequests(params) {
  const requests = await getRequestsLogic(params);
  saveRequestsList(requests);
}
export async function getRequest(id) {
  const request = await getRequestLogic(id);
  saveSelectedRecord(request);
}

export async function updateRequest(selected, values, state) {
  return updateRequestLogic(selected, values, state);
}

export async function postRequest(values) {
  return postRequestLogic(values);
}

export async function getMoratorium(id) {
  return getMoratoriumLogic(id);
}

export async function updateMoratorium(moratorium, values) {
  return updateMoratoriumLogic(moratorium, values);
}
