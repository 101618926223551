import React, { useState } from 'react';
import { Input, Form, Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { main, third } from '../../Utils/Colors';
import { isAuthorized, login, resetPassword } from '../Aplication/Auth.logic';

const { Item } = Form;

const styles = {
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexWrap: 'wrap',
  },
  form: {
    display: 'grid',
  },
  button: {
    backgroundColor: main,
    color: 'white',
    borderColor: main,
    borderRadius: '15px',
    margin: '0.5em',
  },
  border: {
    borderRadius: '15px',
  },
  link: {
    color: main,
    border: 'none',
    borderRadius: '15px',
    margin: '0.5em',
  },
  linkCancel: {
    color: third,
    border: 'none',
    borderRadius: '15px',
    margin: '0.5em',
  },
  avatar: {
    margin: '1em',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: '40vh',
    margin: '2em',
  },
};

export default function Login() {
  const history = useHistory();
  const [forgot, setForgot] = useState(false);

  const validateLogin = async (values) => {
    try {
      await login(values);
      if (await isAuthorized()) {
        message.success('Bienvenido');
        history.push('/requests');
      } else {
        message.error('Algo salió mal');
      }
    } catch (err) {
      message.error('Algo salió mal', err);
    }
  };

  const forgotPassword = async (values) => {
    try {
      await resetPassword(values.email);
      message.success('Su nueva contraseña fue enviada a su correo');
      history.push('/logs');
      setForgot(false);
    } catch (err) {
      setForgot(false);
      message.error('Algo salió mal', err);
    }
  };

  const loginForm = (
    <Form style={styles.form} onFinish={validateLogin}>
      <Item
        label="Correo"
        name="email"
        rules={[
          {
            required: true,
            message: 'Correo no valido',
          },
        ]}
      >
        <Input />
      </Item>
      <Item
        label="Contraseña"
        name="password"
        rules={[
          {
            required: true,
            message: 'Contraseña no valida',
          },
        ]}
      >
        <Input.Password style={styles.border} />
      </Item>
      <Button style={styles.button} type="primary" htmlType="submit">
        Iniciar Sesión
      </Button>
      <Button style={styles.link} type="third" onClick={() => setForgot(true)}>
        Recuperar contraseña
      </Button>
    </Form>
  );

  const forgotPasswordForm = (
    <Form style={styles.form} onFinish={forgotPassword}>
      <Item
        label="Correo"
        name="email"
        rules={[
          {
            required: true,
            message: 'Correo no valido',
          },
        ]}
      >
        <Input />
      </Item>
      <Button style={styles.button} type="primary" htmlType="submit">
        Recuperar Contraseña
      </Button>
      <Button style={styles.linkCancel} onClick={() => setForgot(false)}>
        Cancelar
      </Button>
    </Form>
  );

  return (
    <div style={styles.main}>
      <div>
        <div style={styles.avatar}>
          <img
            style={styles.logo}
            alt="Finloop logo"
            src="https://finlooptest.s3.amazonaws.com/static/images/finloop.png"
          />
        </div>
        {forgot ? forgotPasswordForm : loginForm}
      </div>
    </div>
  );
}
