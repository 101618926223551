/* eslint-disable camelcase */
import moment from 'moment';
import PropTypes from 'prop-types';

export default class RequestModel {
  constructor({
    activation_date,
    amount,
    amounts,
    aval_required,
    comment,
    commission_admin,
    commission_admin_lender,
    commission_by_each_payment,
    opening_commission,
    opening_commission_amount,
    condone_capital,
    condone_commission,
    condone_interest,
    condone_moratorium,
    date,
    description,
    dispersed,
    finish_date,
    guarantee,
    hash,
    hidden_applicant,
    hidden_aval,
    hidden_lender,
    id,
    id_mysql,
    isr_withholdings,
    iva_withholdings,
    iva,
    key,
    merged_contract,
    payment_time,
    payment_type,
    rate,
    recognition,
    request_parent,
    request_parent_data,
    stamped,
    status,
    title,
    token,
    user,
    user_data,
    user_aval,
    user_aval_data,
    user_lender,
    user_lender_data,
    waiting_funds_date,
    version,
    version_commission,
  }) {
    this.activation_date = activation_date ? moment(activation_date).utc() : null;
    this.amount = amount;
    this.amounts = amounts;
    this.aval_required = !!aval_required;
    this.comment = comment;
    this.commission_admin = commission_admin;
    this.commission_admin_lender = commission_admin_lender;
    this.commission_by_each_payment = commission_by_each_payment;
    this.opening_commission = opening_commission;
    this.opening_commission_amount = opening_commission_amount;
    this.condone_capital = condone_capital;
    this.condone_commission = condone_commission;
    this.condone_interest = condone_interest;
    this.condone_moratorium = condone_moratorium;
    this.date = date ? moment(date).utc() : null;
    this.description = description;
    this.dispersed = !!dispersed;
    this.finish_date = finish_date ? moment(finish_date).utc() : null;
    this.guarantee = !!guarantee;
    this.hash = hash;
    this.hidden_applicant = hidden_applicant;
    this.hidden_aval = hidden_aval;
    this.hidden_lender = hidden_lender;
    this.id = id;
    this.id_mysql = id_mysql;
    this.isr_withholdings = isr_withholdings;
    this.iva_withholdings = iva_withholdings;
    this.iva = iva;
    this.key = key;
    this.merged_contract = merged_contract;
    this.payment_time = payment_time;
    this.payment_type = payment_type;
    this.rate = rate;
    this.recognition = recognition;
    this.request_parent = request_parent;
    this.request_parent_data = request_parent_data;
    this.stamped = stamped;
    this.status = status;
    this.title = title;
    this.token = token;
    this.user = user;
    this.user_data = user_data;
    this.user_aval = user_aval;
    this.user_aval_data = user_aval_data;
    this.user_lender = user_lender;
    this.user_lender_data = user_lender_data;
    this.waiting_funds_date = waiting_funds_date ? moment(waiting_funds_date).utc() : null;
    this.version = version;
    this.version_commission = version_commission;
  }

  get json() {
    return {
      activation_date: this.activation_date,
      amount: this.amount,
      amounts: this.amounts,
      aval_required: this.aval_required,
      comment: this.comment,
      commission_admin: this.commission_admin,
      commission_admin_lender: this.commission_admin_lender,
      commission_by_each_payment: this.commission_by_each_payment,
      opening_commission: this.opening_commission,
      opening_commission_amount: this.opening_commission_amount,
      condone_capital: this.condone_capital,
      condone_commission: this.condone_commission,
      condone_interest: this.condone_interest,
      condone_moratorium: this.condone_moratorium,
      date: this.date,
      description: this.description,
      dispersed: this.dispersed,
      finish_date: this.finish_date,
      guarantee: this.guarantee,
      hash: this.hash,
      hidden_applicant: this.hidden_applicant,
      hidden_aval: this.hidden_aval,
      hidden_lender: this.hidden_lender,
      id: this.id,
      id_mysql: this.id_mysql,
      isr_withholdings: this.isr_withholdings,
      iva_withholdings: this.iva_withholdings,
      iva: this.iva,
      key: this.key,
      merged_contract: this.merged_contract,
      payment_time: this.payment_time,
      payment_type: this.payment_type,
      rate: this.rate,
      recognition: this.recognition,
      request_parent: this.request_parent,
      request_parent_data: this.request_parent_data,
      stamped: this.stamped,
      status: this.status,
      title: this.title,
      token: this.token,
      user: this.user,
      user_data: this.user_data,
      user_aval: this.user_aval,
      user_aval_data: this.user_aval_data,
      user_lender: this.user_lender,
      user_lender_data: this.user_lender_data,
      waiting_funds_date: this.waiting_funds_date,
      version: this.version,
      version_commission: this.version_commission,
    };
  }

  static get shape() {
    return PropTypes.shape({
      activation_date: PropTypes.string,
      amount: PropTypes.number,
      amounts: PropTypes.shape({}),
      aval_required: PropTypes.bool,
      comment: PropTypes.string,
      commission_admin: PropTypes.number,
      commission_admin_lender: PropTypes.number,
      commission_by_each_payment: PropTypes.number,
      opening_commission: PropTypes.number,
      opening_commission_amount: PropTypes.number,
      condone_capital: PropTypes.bool,
      condone_commission: PropTypes.bool,
      condone_interest: PropTypes.bool,
      condone_moratorium: PropTypes.bool,
      date: PropTypes.string,
      description: PropTypes.string,
      dispersed: PropTypes.bool,
      finish_date: PropTypes.string,
      guarantee: PropTypes.bool,
      hash: PropTypes.string,
      hidden_applicant: PropTypes.bool,
      hidden_aval: PropTypes.bool,
      hidden_lender: PropTypes.bool,
      id: PropTypes.string,
      id_mysql: PropTypes.number,
      isr_withholdings: PropTypes.number,
      iva_withholdings: PropTypes.number,
      key: PropTypes.string,
      merged_contract: PropTypes.string,
      payment_time: PropTypes.number,
      payment_type: PropTypes.number,
      rate: PropTypes.number,
      iva: PropTypes.number,
      recognition: PropTypes.number,
      request_parent: PropTypes.string,
      request_parent_data: PropTypes.shape({}),
      stamped: PropTypes.bool,
      status: PropTypes.number,
      title: PropTypes.string,
      token: PropTypes.string,
      user: PropTypes.string,
      user_data: PropTypes.shape({}),
      user_aval: PropTypes.string,
      user_aval_data: PropTypes.shape({}),
      user_lender: PropTypes.string,
      user_lender_data: PropTypes.shape({}),
      waiting_funds_date: PropTypes.string,
      version: PropTypes.string,
      version_commission: PropTypes.string,
    });
  }

  static get constants() {
    return {
      ptypes: {
        0: 'Semanal',
        1: 'Quincenal',
        2: 'Mensual',
        3: 'Diario',
      },
      ptypesReverse: {
        Semanal: 0,
        Quincenal: 1,
        Mensual: 2,
        Diario: 3,
      },
      typeRequest: {
        0: 'Préstamo en Pagos Fijos',
        1: 'Reconocimiento de adeudo pagos fijos',
        2: 'Compra a plazos pagos fijos',
        3: 'Préstamo en Cuenta Corriente',
        4: 'Reconocimiento de adeudo en cuenta corriente',
      },
      ptypesVersion: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  }
}
