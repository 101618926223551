/* eslint-disable no-unused-vars */
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import SiteLayout from './Layout/SiteLayout';
import './Utils/fonts/fonts.css';
import 'antd/dist/antd.css';
import './App.css';
import Login from './Login/UI/Login';
import PrivateRoute from './Login/Aplication/AuthProvider';
import store from './Redux/store';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <PrivateRoute exact path="/">
            <Redirect to="/requests" />
          </PrivateRoute>
          <PrivateRoute exact path="/logs">
            <SiteLayout />
          </PrivateRoute>
          <PrivateRoute exact path="/logs/:id">
            <SiteLayout />
          </PrivateRoute>
          <PrivateRoute exact path="/requests">
            <SiteLayout />
          </PrivateRoute>
          <PrivateRoute exact path="/requests/:id">
            <SiteLayout />
          </PrivateRoute>
          <PrivateRoute exact path="/users">
            <SiteLayout />
          </PrivateRoute>
          <PrivateRoute exact path="/users/:id">
            <SiteLayout />
          </PrivateRoute>
          <Route path="/login">
            <Login />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
