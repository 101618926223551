/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
// A wrapper for <Route> that redirects to the login
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isValidSession } from './Auth.logic';

export default function PrivateRoute({ children, ...rest }) {
  const [state, setState] = React.useState('loading');

  useEffect(() => {
    (async function checkSession() {
      const is_valid = await isValidSession();
      setState(is_valid ? 'logged' : 'redirect');
    })();
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        switch (state) {
          case 'loading':
            return <div>Loading...</div>;
          case 'logged':
            return children;
          case 'redirect':
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            );
          default:
            return <div>Something went wrong</div>;
        }
      }}
    />
  );
}
