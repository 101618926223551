import moment from 'moment';

export default class MoratoriumModel {
  constructor({
    amount_paid,
    days,
    interest,
    iva,
    total,
    paid,
    q_amount,
    capital,
    date_final,
    date_initial,
    period,
    request,
    rate,
    id,
  }) {
    this.amount_paid = amount_paid;
    this.days = days;
    this.interest = interest;
    this.iva = iva;
    this.total = total;
    this.paid = paid;
    this.q_amount = q_amount;
    this.capital = capital;
    this.date_final = date_final ? moment(date_final).utc() : null;
    this.date_initial = date_initial ? moment(date_initial).utc() : null;
    this.period = period;
    this.request = request;
    this.rate = rate;
    this.id = id;
  }

  get json() {
    return {
      amount_paid: this.amount_paid,
      days: this.days,
      interest: this.interest,
      iva: this.iva,
      total: this.total,
      paid: this.paid,
      q_amount: this.q_amount,
      capital: this.capital,
      date_final: this.date_final,
      date_initial: this.date_initial,
      period: this.period,
      request: this.request,
      rate: this.rate,
      id: this.id,
    };
  }

  static get constants() {
    return {
      ptypesQ: {
        0: 'NO',
        1: 'SI',
      },
      ptypesQtest: {
        0: '0',
        1: '1',
      },
    };
  }
}
