// eslint-disable-next-line import/prefer-default-export
export const type = [
  {
    value: 0,
    typeName: 'Préstamo pagos fijos',
  },
  {
    value: 1,
    typeName: 'Reconocimiento de adeudo pagos fijos',
  },
  {
    value: 3,
    typeName: 'Préstamo en cuenta corriente',
  },
  {
    value: 4,
    typeName: 'Reconocimiento de adeudo en cuenta corriente',
  },
];

export const version = [
  {
    value: 'v1',
    typeName: 'v1',
  },
  {
    value: 'v2',
    typeName: 'v2',
  },
];
