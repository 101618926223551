/* eslint-disable no-underscore-dangle */
import { Button, Col, Form, Row, Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Title } from '../../Utils/Text';
import { getRequest, getRequests } from '../Aplication/Requests.business';
// eslint-disable-next-line import/named
import { getRequestSelector } from '../Infrastructure/Requests.reducer';
import CreateRequest from './CreateRequest';
import styles2 from './Styles.css';
import { secondary } from '../../Utils/Colors';

const { Option } = Select;

const styles = {
  container: {},
  background: {
    margin: '0.7em 0em',
    borderRadius: 25,
    padding: '1em',
    boxShadow: '3px 5px 5px 1px rgb(213,196,233, 0.5)',
  },
  button: {
    borderRadius: 25,
    backgroundColor: secondary,
  },
  select: {
    borderRadius: 25,
    width: 200,
  },
};

// eslint-disable-next-line react/prop-types
export default function Searcher() {
  const records = useSelector(getRequestSelector);
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = async ({ id }) => {
    getRequest(id);
    history.push(`/requests/${id}`);
  };

  const onReset = () => {
    form.resetFields();
  };

  function onSearch(val) {
    getRequests({ q: val });
  }
  return (
    <div style={styles.container}>
      <Row>
        <Col span={24}>
          <Title>Solicitudes</Title>
          <CreateRequest />
        </Col>
        <Col span={24}>
          <div style={styles.background} className={styles2}>
            <Form form={form} layout="inline" onFinish={onFinish} onReset={onReset}>
              <Form.Item name="id" label="Token">
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Selecciona un token"
                  optionFilterProp="children"
                  /* eslint-disable-next-line react/jsx-no-bind */
                  onSearch={onSearch}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {records?.map((record) => (
                    <Option value={record.id} key={record.id}>
                      {record.token}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Button type="primary" color={secondary} htmlType="submit" style={styles.button}>
                Seleccionar
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}
