class User {
  static get role_super_admin() {
    return 'SUPER_ADMIN';
  }

  static get role_admin() {
    return 'ADMIN';
  }

  static get role_senior_operations() {
    return 'SENIOR_OPERATIONS';
  }

  static get rolesNames() {
    return {
      0: 'LENDER',
      1: 'APPLICANT',
      2: 'AVAL',
      3: 'ADMIN',
      LENDER: 0,
      APPLICANT: 1,
      AVAL: 2,
      ADMIN: 3,
    };
  }

  static get constants() {
    return {
      typeAccount: {
        personal: 'Fisica',
        company: 'Moral',
      },
    };
  }

  static rolesDictionary(role) {
    switch (role) {
      case User.roles_names.APPLICANT:
        return 'Solicitante';
      case User.roles_names.AVAL:
        return 'Aval';
      case User.roles_names.LENDER:
        return 'Prestador';
      default:
        return 'Sistema';
    }
  }

  constructor({
    additional,
    address,
    average_applicant,
    average_lender,
    beneficiary,
    can_publish,
    cellphone,
    curp,
    data,
    email,
    financial,
    fout,
    general,
    has_lender,
    id,
    id_mysql,
    last_login,
    logged,
    password,
    roles,
    ranking,
    revoked,
    score,
    signature,
    signature_url,
    terms_signed,
    touched,
    username,
    validated,
    type_account,
  }) {
    this.additional = additional;
    this.address = address;
    this.average_applicant = average_applicant;
    this.average_lender = average_lender;
    this.beneficiary = beneficiary;
    this.can_publish = can_publish;
    this.cellphone = cellphone;
    this.curp = curp;
    this.data = data;
    this.email = email;
    this.financial = financial;
    this.fout = fout;
    this.general = general;
    this.has_lender = has_lender;
    this.id = id;
    this.id_mysql = id_mysql;
    this.last_login = last_login;
    this.logged = logged;
    this.password = password;
    this.roles = roles;
    this.ranking = ranking;
    this.revoked = revoked;
    this.score = score;
    this.signature = signature;
    this.signature_url = signature_url;
    this.terms_signed = terms_signed;
    this.touched = touched;
    this.username = username;
    this.validated = validated;
    this.type_account = type_account;
  }

  static formatPhone(phone) {
    return !phone ? null : phone.slice(3, 13);
  }
}

export default User;
