import { Col, Layout, Row } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { background } from '../../Utils/Colors';
import { getRequest, getRequests } from '../Aplication/Requests.business';
import RequestsForm from './Requests.form';
import Searcher from './Searcher';

const { Content } = Layout;

export default function Requests() {
  const { id } = useParams();
  useEffect(() => {
    getRequests();
    getRequest(id);
  }, []);

  return (
    <Layout>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          background,
        }}
      >
        <Row
          justify="space-between"
          style={{
            width: '100%',
          }}
        >
          <Col span={24}>
            <Searcher requestID={id} />
          </Col>
          <Col span={24}>
            <RequestsForm requestID={id} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
