import { getList, getOne, updateOne } from '../../Data/provider';

export function getUsersService(params) {
  return getList('users', params);
}

export function getUserService(id) {
  return getOne('users', id);
}

export function updateUserService(id, data) {
  return updateOne('users', id, data);
}
