import { getUserRepository, getUsersRepository, updateUserRepository } from '../Infrastructure/Users.repository';

export function getUsersLogic(params) {
  return getUsersRepository(params);
}
export function getUserLogic(id) {
  return getUserRepository(id);
}

export function updateUserLogic(selected, values) {
  const data = {
    cellphone: values.cellphone,
    phone: values.phone,
    email: values.email,
    type_account: values.type_account,
  };

  return updateUserRepository(selected.id, data);
}
