import User from '../../Users/Domain/User.model';

export default class RolesModel {
  constructor({ id, roles }) {
    this.id = id;
    this.roles = roles;
  }

  isAuthorized() {
    const authorized = [User.role_super_admin, User.role_admin, User.role_senior_operations];
    return this.roles.some((item) => authorized.includes(item));
  }
}
