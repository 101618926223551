import AuthModel from '../Domain/Auth.model';
import RolesModel from '../Domain/Roles.model';
import {
  getRoles,
  getSession,
  loginService,
  logoutService,
  requestPasswordService,
  resetPasswordService,
} from './Login.service';

export async function loginRepository(loginModel) {
  const response = await loginService(loginModel);
  return new AuthModel({
    token: response.headers.authorization,
    userId: response.data.id,
  });
}

export async function getRolesRepository() {
  const response = await getRoles();
  return new RolesModel({
    id: response.data.id,
    roles: response.data.roles,
  });
}

export async function getSessionRepository() {
  return getSession();
}

export async function logoutRepository(authModel) {
  return logoutService(authModel);
}

export async function resetPasswordRepository(resetPasswordModel) {
  return resetPasswordService(resetPasswordModel);
}
export async function requestPasswordRepository(id) {
  return requestPasswordService(id);
}
