// eslint-disable-next-line import/named
import { cancelSelectedRecord, selectRecord, setRecords, updateRecord } from './Requests.reducer';
import store from '../../Redux/store';

export function saveRequestsList(records) {
  store.dispatch(setRecords(records));
}
export function saveSelectedRecord(record) {
  store.dispatch(selectRecord(record));
}

export function deselectRecord() {
  store.dispatch(cancelSelectedRecord());
}

export function saveUpdateRecord(index, record) {
  store.dispatch(updateRecord(index, record));
}
