/* eslint-disable no-unused-vars */
import React from 'react';
import { Input, Form, Button, Row, Col, message, Select, PageHeader, Descriptions, Image, Popconfirm } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ExportOutlined } from '@ant-design/icons';
import { main, secondary } from '../../Utils/Colors';
import UserModel from '../Domain/User.model';
import { deselectUser } from '../Infrastructure/Users.store';
import { getSelectedUserSelector } from '../Infrastructure/Users.reducer';
import { requestPassword } from '../../Login/Aplication/Auth.logic';
import { deselectRecord } from '../../Requests/Infrastructure/Requests.store';
// eslint-disable-next-line import/named
import { updateUser } from '../Application/Users.business';
import PasswordTemporal from './passwordTemporal';

const { Option } = Select;

const styles = {
  background: {
    margin: '0.7em 0em',
    borderRadius: '15px',
    padding: '1em',
    boxShadow: '3px 5px 5px 1px rgb(213,196,233, 0.5)',
    transition: 'all 0.2s',
  },
};

export default function UsersForm() {
  const selected = useSelector(getSelectedUserSelector);
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  const [form] = Form.useForm();

  const onFinishForm = async (values) => {
    updateUser(selected, values)
      .then(() => {
        const urlId = selected.id;
        message.success('Usuario modifcado con éxito');
        deselectUser();
        history.push(`/users/${urlId}`);
        window.location.reload();
      })
      .catch((e) => {
        const messageError = e.request.response;
        message.error(`Error al modificar usuario ${messageError}`);
      });
  };
  const cancelForm = () => {
    deselectUser();
  };
  const cancel = () => {
    deselectUser();
    history.replace('/users');
  };

  if (selected) {
    form.setFieldsValue({
      ...selected,
    });
  }
  return selected ? (
    <div style={styles.background}>
      <PageHeader
        className="site-page-header"
        onBack={cancel}
        title={`Usuario: ${selected.username}`}
        extra={[
          <PasswordTemporal data={selected} />,
          <Link key="external" to={{ pathname: `https://dashboard.finloop.mx/#/users/${selected.id}` }} target="_blank">
            <span>Abrir en Dashboard</span>
            <ExportOutlined style={{ marginLeft: '1em' }} />
          </Link>,
        ]}
      >
        <Form form={form} onFinish={onFinishForm} initialValues={selected?.general}>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Descriptions size="small" column={3}>
              <Descriptions.Item label="Nombre">{selected.general.name}</Descriptions.Item>
              <Descriptions.Item label="Apellido Paterno">{selected.general.last_name}</Descriptions.Item>
              <Descriptions.Item label="Apellido Materno">{selected.general.second_last_name}</Descriptions.Item>

              <Descriptions.Item label="CURP">{selected.general.curp}</Descriptions.Item>
              <Descriptions.Item label="RFC">{selected.general.rfc}</Descriptions.Item>
            </Descriptions>
          </Row>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col className="gutter-row" span={8}>
              <Form.Item hasFeedback name="cellphone" label="Celular">
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item hasFeedback name="phone" label="Teléfono">
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item hasFeedback name="email" label="Email">
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={8}>
              <Form.Item hasFeedback name="type_account" label="Tipo de cuenta">
                <Select showSearch>
                  {Object.keys(UserModel.constants.typeAccount).map((key) => (
                    <Option key={key} value={key}>
                      {UserModel.constants.typeAccount[key]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Image width={100} src={selected.general.selfie} />
          <br />
          <br />
          <Row justify="space-between">
            <Col>
              <Button onClick={cancelForm} danger>
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                style={{
                  background: main,
                }}
                htmlType="submit"
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </PageHeader>
    </div>
  ) : (
    <div />
  );
}
