export default class Params {
  constructor({ limit, page }) {
    this.limit = limit;
    this.page = page;
  }

  static buildFilters(params) {
    if (!params) return {};
    const { ids, limit, page, q } = params;
    return {
      page,
      limit,
      filter: {
        ids,
        q,
      },
    };
  }
}
