/* eslint-disable no-underscore-dangle */
import { Button, Col, Form, Input, Row, Select, Radio, message, InputNumber } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRequests, postRequest } from '../Aplication/Requests.business';
import { getRequestSelector } from '../Infrastructure/Requests.reducer';
import { getUsersSelector } from '../../Users/Infrastructure/Users.reducer';
import { getUsers } from '../../Users/Application/Users.business';

// eslint-disable-next-line import/named
import { type, version } from '../../Data/Request/data';
import styles2 from './Styles.css';

const styles = {
  container: {},
  background: {
    margin: '0.7em 0em',
    borderRadius: '15px',
    padding: '1em',
    boxShadow: '3px 5px 5px 1px rgb(213,196,233, 0.5)',
  },
  button: {
    borderRadius: 25,
    marginLeft: 25,
    backgroundColor: '#00b400',
    color: 'white',
    top: '-6px',
  },
};

export default function CreateRequest() {
  const [value, setValue] = useState(false);
  const [value1, setValue1] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const recordsUser = useSelector(getUsersSelector);
  const history = useHistory();
  const records = useSelector(getRequestSelector);
  const { Option } = Select;
  const [form] = Form.useForm();

  const onSubmit = useCallback((values) => {
    postRequest(values)
      .then((values) => {
        const urlId = values.data.id;
        closePopup();
        message.success('Se creo la solicitud con exito');
        history.push(`/requests/${urlId}`);
        window.location.reload();
      })
      .catch((e) => {
        const messageError = e.request.response;
        message.error(`Error al crear la solicitud ${messageError}`);
      });
  }, []);

  const closePopup = useCallback(() => {
    form.resetFields();
    setIsOpen(false);
  }, [form]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // const onChangeInterest = (e) => {
  //   const valueInteres = e.target.value;
  //   if (valueInteres > 100) {
  //     message.error(`El valor no puede ser mayor de 100`);
  //   } else if (valueInteres < 5) {
  //     message.error(`El valor no puede ser menor a 5`);
  //   }
  // };

  const onChange1 = ({ target: { value } }) => {
    setValue1(value);
  };

  function onSearch(val) {
    getUsers({ q: val });
  }

  function onSearchToken(val) {
    getRequests({ q: val });
  }
  return (
    <>
      <Button type="secondary" onClick={setIsOpen} style={styles.button}>
        Crear Solicitud
      </Button>
      <Modal
        title="Crear Solicitud"
        visible={isOpen}
        onOk={form.submit}
        onCancel={closePopup}
        okText="Crear"
        cancelText="Cancelar"
        width={750}
      >
        <Form form={form} onFinish={onSubmit}>
          <Row gutter={[24, 16]} className={styles2}>
            <Col span={15}>
              <Col span={24}>
                <Form.Item name="user" label="Solicitante" rules={[{ required: true, message: 'No puede ir vacio' }]}>
                  <Select
                    style={{ width: 225 }}
                    placeholder="Selecciona un solicitante"
                    showSearch
                    optionFilterProp="children"
                    /* eslint-disable-next-line react/jsx-no-bind */
                    onSearch={onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {recordsUser?.map((recordUser) => (
                      <Option style={{ borderRadius: 25 }} value={recordUser.id} key={recordUser.id}>
                        {recordUser.username || recordUser.email}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="request_parent" label="Token padre">
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Selecciona un token"
                    optionFilterProp="children"
                    /* eslint-disable-next-line react/jsx-no-bind */
                    onSearch={onSearchToken}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {records?.map((record) => (
                      <Option value={record.id} key={record.id}>
                        {record.token}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="user_lender" label="Prestador" rules={[{ required: false }]}>
                  <Select
                    style={{ width: 200 }}
                    placeholder="Selecciona un prestador"
                    showSearch
                    optionFilterProp="children"
                    /* eslint-disable-next-line react/jsx-no-bind */
                    onSearch={onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {recordsUser?.map((recordUser) => (
                      <Option value={recordUser.id} key={recordUser.id}>
                        {recordUser.username || recordUser.email}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="user_aval" label="Aval">
                  <Select
                    style={{ width: 200 }}
                    placeholder="Selecciona un aval"
                    showSearch
                    optionFilterProp="children"
                    /* eslint-disable-next-line react/jsx-no-bind */
                    onSearch={onSearch}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {recordsUser?.map((recordUser) => (
                      <Option value={recordUser.id} key={recordUser.id}>
                        {recordUser.username || recordUser.email}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  hasFeedback
                  name="recognition"
                  label="Tipo"
                  rules={[{ required: true, message: 'Tipo no puede estar vacio' }]}
                  style={{ borderRadius: 20 }}
                >
                  {/* eslint-disable-next-line react/destructuring-assignment,react/no-this-in-sfc */}
                  <Select placeholder="Selecciona el tipo" showSearch optionFilterProp="children">
                    {type.map((type) => (
                      <Option key={type.value} value={type.value}>
                        {type.typeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item hasFeedback name="version" label="Versión de calculos">
                  {/* eslint-disable-next-line react/destructuring-assignment,react/no-this-in-sfc */}
                  <Select style={{ width: 100 }} showSearch placeholder="Escoge la versión">
                    {version.map((version) => (
                      <Option key={version.value} value={version.value}>
                        {version.typeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item hasFeedback name="version_commission" label="Versión de comisión">
                  <Select style={{ width: 100 }} showSearch placeholder="Escoge versión">
                    {version.map((version) => (
                      <Option key={version.value} value={version.value}>
                        {version.typeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  name="title"
                  label="Título de solicitud"
                  rules={[{ required: true, message: 'No puede ir vacio' }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item hasFeedback name="description" label="Descripción">
                  <Input.TextArea style={{ borderRadius: 20 }} />
                </Form.Item>
              </Col>
            </Col>
            <Col span={9}>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  name="amount"
                  label="Monto"
                  rules={[
                    { required: true, message: 'Monto no puede estar vacio' },
                    {
                      message: 'Solo se permiten numeros y dos decimales',
                      pattern: /^\d+(\.\d{1,2})?$/,
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: 160, borderRadius: 20 }}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  name="payment_time"
                  label="Periodicidad"
                  rules={[
                    { required: true, message: 'Tiempo no puede estar vacio' },
                    {
                      message: 'Solo se permiten números',
                      pattern: /^\d*$/,
                    },
                    () => ({
                      validator(rule, value) {
                        const numValue = Number(value);
                        if (!numValue || (numValue >= 1 && numValue <= 120)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Solo puedes usar 1-120'));
                      },
                    }),
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  name="payment_type"
                  label="Tipo de pago"
                  rules={[{ required: true, message: 'Tipo de pago no puede estar vacio' }]}
                >
                  <Select showSearch>
                    <Option hidden value="*">
                      Diario
                    </Option>
                    <Option value="0">Semanal</Option>
                    <Option value="1">Quincenal</Option>
                    <Option value="2">Mensual</Option>
                    <Option value="3">Diario</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  name="rate"
                  label="Tasa de interes"
                  rules={[
                    { required: true, message: 'Tasa de interes no puede ir vacia' },
                    {
                      message: 'Solo se permiten dos decimales',
                      pattern: /^\d+(\.\d{1,2})?$/,
                    },
                  ]}
                >
                  <Input style={{ borderRadius: 20 }} suffix="%" type="number" min={5} max={100} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item hasFeedback name="aval_required" label="Aval">
                  <Radio.Group onChange={onChange1} defaultValue={false} value={value1}>
                    <Radio value>SI</Radio>
                    <Radio value={false}>NO</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item hasFeedback name="guarantee" label="Garantía">
                  <Radio.Group onChange={onChange} defaultValue={false} value={value}>
                    <Radio value>SI</Radio>
                    <Radio value={false}>NO</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
