import {
  saveUsersList,
  saveSelectedUser,
  // saveUpdateUser,
} from '../Infrastructure/Users.store';
import { getUserLogic, getUsersLogic, updateUserLogic } from './Users.logic';

export async function getUsers(params) {
  const requests = await getUsersLogic(params);
  saveUsersList(requests);
}

export async function updateUser(selected, values, state) {
  return updateUserLogic(selected, values, state);
}

export async function getUser(id) {
  const request = await getUserLogic(id);
  saveSelectedUser(request);
}
