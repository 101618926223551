/* eslint-disable camelcase,react/jsx-curly-newline */
import React from 'react';
import {
  Input,
  Form,
  Button,
  Row,
  Col,
  Switch,
  DatePicker,
  message,
  Select,
  PageHeader,
  Descriptions,
  Tag,
  Tabs,
  InputNumber,
} from 'antd';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ExportOutlined } from '@ant-design/icons';
import { main, secondary } from '../../Utils/Colors';
import { getRequests, updateRequest } from '../Aplication/Requests.business';
import RequestModel from '../Domain/Request.model';
import { deselectRecord } from '../Infrastructure/Requests.store';
import { getRequestSelector, getSelectedRequest } from '../Infrastructure/Requests.reducer';
import { getUsersSelector } from '../../Users/Infrastructure/Users.reducer';
import { getUsers } from '../../Users/Application/Users.business';
import styles2 from './Styles.css';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import Moratorium from './Moratorium';

const { Option } = Select;

const { TabPane } = Tabs;

const styles = {
  background: {
    margin: '0.7em 0em',
    borderRadius: '15px',
    padding: '1em',
    boxShadow: '3px 5px 5px 1px rgb(213,196,233, 0.5)',
    transition: 'all 0.2s',
  },
  button: {
    size: 'large',
  },
};

export default function RequestsForm() {
  // eslint-disable-next-line react/no-this-in-sfc

  const selected = useSelector(getSelectedRequest);
  const recordsUser = useSelector(getUsersSelector);
  const records = useSelector(getRequestSelector);

  // const valueUser = useSelector(getUser(selected.user));

  const newValueUser = selected?.user_data?.username;
  const newValueUserLender = selected?.user_lender_data?.username;
  const newValueRequestParent = selected?.request_parent_data?.token;
  const newValueUserAval = selected?.user_aval_data?.username;

  const newSelected = {
    ...selected,
    user: newValueUser,
    user_lender: newValueUserLender,
    request_parent: newValueRequestParent,
    user_aval: newValueUserAval,
  };

  const history = useHistory();

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    updateRequest(selected, values)
      .then(() => {
        const urlId = selected.id;
        message.success('Solicitud modificada con éxito');
        deselectRecord();
        history.push(`/requests/${urlId}`);
        window.location.reload();
      })
      .catch((e) => {
        const messageError = e.request.response;
        message.error(`Error al modificar solicitud ${messageError}`);
      });
  };

  const cancel = () => {
    deselectRecord();
  };

  function onSearchUser(val) {
    getUsers({ q: val, type: 'user' });
  }

  function onSearchUserLender(val) {
    getUsers({ q: val, type: 'user_lender' });
  }

  function onSearchUserAval(val) {
    getUsers({ q: val, type: 'user_aval' });
  }

  function onSearchToken(val) {
    getRequests({ q: val });
  }

  if (selected) {
    form.setFieldsValue({
      ...newSelected,
      payment_type: selected?.payment_type.toString(),
      // RequestModel.constants.ptypes[selected?.payment_type],
      recognition: selected?.recognition?.toString(),
      status: selected?.status?.toString(),
    });
  }

  return selected ? (
    <div style={styles.background} className={styles2}>
      <PageHeader
        className="site-page-header"
        onBack={deselectRecord}
        title={`Solicitud: ${selected.id}`}
        extra={[
          <Link
            key="external"
            to={{ pathname: `${process.env.REACT_APP_HOST}/#/requests/${selected.id}` }}
            target="_blank"
          >
            <span>Abrir en Dashboard</span>
            <ExportOutlined style={{ marginLeft: '1em' }} />
          </Link>,
        ]}
      >
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="Token">
            <Tag color={secondary}>{selected.token}</Tag>
          </Descriptions.Item>
          {/* <Descriptions.Item>
            <Button type="primary" shape="round" style={styles.button} onClick={() => handleClick(selected.id)}>
              Calcular
            </Button>
          </Descriptions.Item> */}
        </Descriptions>
      </PageHeader>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Información" key="1">
          <Form form={form} onFinish={onFinish} initialValues={selected}>
            <Row gutter={[48, 16]}>
              <Col span={8}>
                <PageHeader title="Información General" />
                <Row gutter={[24, 16]}>
                  <Col span={16}>
                    <Form.Item
                      hasFeedback
                      name="status"
                      label="Status"
                      rules={[{ required: true, message: 'Status no puede estar vacio' }]}
                    >
                      <Select showSearch>
                        <Option value="0">En espera de prestador</Option>
                        <Option value="1">Activa</Option>
                        <Option value="3">Cancelada</Option>
                        <Option value="4">Finalizada</Option>
                        <Option value="5">Expirada</Option>
                        <Option value="6">En Espera de Fondos</Option>
                        <Option value="7">En Espera de Aval</Option>
                        <Option value="10">En Espera de Firmas</Option>
                        <Option value="8">Mesa de Control</Option>
                        <Option value="9">Por Renovar</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="request_parent" label="Token padre">
                      <Select
                        showSearch
                        placeholder="Selecciona un token"
                        optionFilterProp="children"
                        /* eslint-disable-next-line react/jsx-no-bind */
                        onSearch={onSearchToken}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {records?.map((record) => (
                          <Option value={record.id} key={record.id}>
                            {record.token}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/*   <Col span={24}>
                    <Form.Item
                      hasFeedback
                      name="user"
                      label="Solicitante"
                      rules={[
                        {
                          required: true,
                          message: 'Solicitante no puede estar vacio',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
*/}

                  <Col span={24}>
                    <Form.Item
                      name="user"
                      label="Solicitante"
                      rules={[{ required: true, message: 'No puede ir vacio' }]}
                    >
                      <Select
                        placeholder="Selecciona un usuario"
                        showSearch
                        optionFilterProp="children"
                        /* eslint-disable-next-line react/jsx-no-bind */
                        onSearch={onSearchUser}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {recordsUser?.map((recordUser) => (
                          <Option value={recordUser.id} key={recordUser.id}>
                            {recordUser.username || recordUser.email}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/*          <Col span={24}>
                    <Form.Item hasFeedback name="user_lender" label="Prestador">
                      <Input />
                    </Form.Item>
                  </Col>
*/}
                  <Col span={24}>
                    <Form.Item name="user_lender" label="Prestador">
                      <Select
                        placeholder="Selecciona un usuario"
                        showSearch
                        optionFilterProp="children"
                        /* eslint-disable-next-line react/jsx-no-bind */
                        onSearch={onSearchUserLender}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {recordsUser?.map((recordUser) => (
                          <Option value={recordUser.id} key={recordUser.id}>
                            {recordUser.username || recordUser.email}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="user_aval" label="Aval">
                      <Select
                        placeholder="Selecciona un usuario"
                        showSearch
                        optionFilterProp="children"
                        /* eslint-disable-next-line react/jsx-no-bind */
                        onSearch={onSearchUserAval}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {recordsUser?.map((recordUser) => (
                          <Option value={recordUser.id} key={recordUser.id}>
                            {recordUser.username || recordUser.email}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      name="recognition"
                      label="Producto"
                      rules={[
                        {
                          required: true,
                          message: 'Tipo de solicitud no puede estar vacio',
                        },
                      ]}
                    >
                      <Select showSearch>
                        {Object.keys(RequestModel.constants.typeRequest).map((key) => (
                          <Option key={key} value={key}>
                            {RequestModel.constants.typeRequest[key]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      name="title"
                      label="Título"
                      rules={[{ required: true, message: 'Título no puede estar vacio' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      name="description"
                      label="Descripción"
                      rules={[
                        {
                          required: true,
                          message: 'Descripción no puede estar vacio',
                        },
                      ]}
                    >
                      <Input.TextArea style={{ borderRadius: 20 }} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item hasFeedback name="comment" label="Comentarios">
                      <Input.TextArea style={{ borderRadius: 20 }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <PageHeader title="Resumen del préstamo" />
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      name="amount"
                      label="Capital"
                      rules={[
                        { required: true, message: 'Monto no puede estar vacio' },
                        {
                          message: 'Solo se permiten dos digitos',
                          pattern: /^\d+(\.\d{1,2})?$/,
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: 150, borderRadius: 20 }}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      name="rate"
                      label="Tasa"
                      rules={[
                        { required: true, message: 'Tasa no puede estar vacio' },
                        {
                          message: 'Solo se permiten números',
                          pattern: /^\d+(\.\d{1,2})?$/,
                        },
                      ]}
                    >
                      <Input suffix="%" type="number" style={{ width: 95, borderRadius: 20 }} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item hasFeedback name="iva" label="IVA">
                      <Select suffix="%" style={{ width: 100 }} showSearch>
                        <Option value="0">0</Option>
                        <Option value="16">16</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item hasFeedback name="isr_withholdings" label="Tasa de retención de ISR">
                      <Input suffix="%" type="number" style={{ width: 95, borderRadius: 20 }} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item hasFeedback name="iva_withholdings" label="Tasa de retención de IVA">
                      <Input suffix="%" type="number" style={{ width: 95, borderRadius: 20 }} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      hasFeedback
                      name="payment_time"
                      label="Periodicidad"
                      rules={[
                        { required: true, message: 'Tiempo no puede estar vacio' },
                        {
                          message: 'Solo se permiten números',
                          pattern: /^\d*$/,
                        },
                        () => ({
                          validator(rule, value) {
                            const numValue = Number(value);
                            if (!numValue || (numValue >= 1 && numValue <= 120)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Solo puedes usar 1-120'));
                          },
                        }),
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      name="payment_type"
                      label="Plazo"
                      rules={[
                        {
                          required: true,
                          message: 'Plazo no puede estar vacio',
                        },
                      ]}
                    >
                      <Select showSearch style={{ width: 150 }}>
                        {Object.keys(RequestModel.constants.ptypes).map((key) => (
                          <Option key={key} value={key}>
                            {RequestModel.constants.ptypes[key]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      name="date"
                      label="Fecha"
                      rules={[{ required: true, message: 'Fecha no puede estar vacio' }]}
                    >
                      <DatePicker style={{ borderRadius: 20 }} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item hasFeedback name="activation_date" label="Fecha de Activación">
                      <DatePicker style={{ borderRadius: 20 }} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item hasFeedback name="finish_date" label="Fecha de Finalización">
                      <DatePicker style={{ borderRadius: 20 }} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      name="waiting_funds_date"
                      label="Fecha de Espera de fondos"
                      rules={[{ required: false }]}
                    >
                      <DatePicker style={{ borderRadius: 20 }} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item hasFeedback name="dispersed" label="Dispersado" initialValue={selected?.dispersed}>
                      <Switch defaultChecked={selected?.dispersed} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      hasFeedback
                      name="aval_required"
                      label="Aval requerido"
                      initialValue={selected.aval_required}
                    >
                      <Switch defaultChecked={selected.aval_required} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback name="guarantee" label="Garantía" initialValue={selected?.guarantee}>
                      <Switch defaultChecked={selected.guarantee} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <PageHeader title="Comisiones" />
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Comisión no puede estar vacia',
                        },
                      ]}
                      name="commission_admin"
                      label="Comisión por admin solicitante"
                    >
                      <InputNumber
                        style={{ borderRadius: 20 }}
                        min={0}
                        max={10}
                        step={0.5}
                        formatter={(value) => `${parseFloat(value).toFixed(5)}`} // Formatea el valor como número decimal con un decimal
                        parser={(value) => parseFloat(value)} // Analiza el valor como un número decimal
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Comisión no puede estar vacia',
                        },
                      ]}
                      name="commission_admin_lender"
                      label="Comisión por admin prestador"
                    >
                      <InputNumber
                        style={{ borderRadius: 20 }}
                        min={0}
                        max={10}
                        step={0.5}
                        formatter={(value) => `${parseFloat(value).toFixed(5)}`} // Formatea el valor como número decimal con un decimal
                        parser={(value) => parseFloat(value)} // Analiza el valor como un número decimal
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Comisión no puede estar vacia',
                        },
                      ]}
                      name="opening_commission"
                      label="Tasa de comisión por apertura"
                    >
                      <InputNumber
                        style={{ borderRadius: 20 }}
                        min={0}
                        max={10}
                        step={0.5}
                        formatter={(value) => `${parseFloat(value).toFixed(5)}`} // Formatea el valor como número decimal con un decimal
                        parser={(value) => parseFloat(value)} // Analiza el valor como un número decimal
                      />

                      {/* <Input suffix="%" type="number" step="0.01" pattern="^\d*(\.\d{0,2})?$" /> */}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Comisión no puede estar vacia',
                        },
                      ]}
                      name="commission_by_each_payment"
                      label="Comisión por cada pago"
                    >
                      <Input type="number" prefix="$" style={{ width: 100, borderRadius: 20 }} />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Comisión no puede estar vacia',
                        },
                      ]}
                      name="opening_commission_amount"
                      label="Monto  de comisión por apertura"
                    >
                      <InputNumber
                        style={{ width: 150, borderRadius: 20 }}
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      />

                      {/* <Input suffix="%" type="number" step="0.01" pattern="^\d*(\.\d{0,2})?$" /> */}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <PageHeader title="Información de Desarrollo" />
                <Row gutter={[24, 16]}>
                  <Col span={24}>
                    <Form.Item hasFeedback name="version" label="Versión de calculos">
                      <Select showSearch style={{ width: 100 }}>
                        {Object.keys(RequestModel.constants.ptypesVersion).map((key) => (
                          <Option key={key} value={key}>
                            {RequestModel.constants.ptypesVersion[key]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item hasFeedback name="version_commission" label="Versión de comisión">
                      <Select showSearch style={{ width: 100 }}>
                        {Object.keys(RequestModel.constants.ptypesVersion).map((key) => (
                          <Option key={key} value={key}>
                            {RequestModel.constants.ptypesVersion[key]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item hasFeedback name="hash" label="Hash">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item hasFeedback name="key" label="Key">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item hasFeedback name="merged_contract" label="Unión de Contratos">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback name="hidden_applicant" label="Ocultar Solicitante">
                      <Switch defaultChecked={selected.hidden_applicant} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback name="hidden_lender" label="Ocultar Prestador">
                      <Switch defaultChecked={selected.hidden_lender} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback name="hidden_aval" label="Ocultar Aval">
                      <Switch defaultChecked={selected.hidden_aval} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback name="stamped" label="Estampado">
                      <Switch defaultChecked={selected.stamped} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Button onClick={cancel} danger>
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  style={{
                    background: main,
                  }}
                  htmlType="submit"
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab="Moratorios" key="2">
          <Moratorium selected={selected} />
        </TabPane>
        <TabPane tab="Contratos" key="3">
          Contratos
        </TabPane>

        <TabPane tab="Pagos" key="4">
          En construcción
        </TabPane>
      </Tabs>
    </div>
  ) : (
    <div />
  );
}
